<template>
    <div class="page page-bg">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('绑定邮箱')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper-bg">
            <div class="shadow-lg bind-panel bs-form">
                <div class="one-card">
                    <div style="font-size: 14px;color: #ffffff;display: flex;justify-content: center;flex-direction: column;align-items: center">
                        <van-field v-model="email" v-if="y_email == ''" :placeholder="$t('请输入您的邮箱')">
                        </van-field>
                        <van-field v-model="y_email" v-else readonly disabled>
                        </van-field>

                        <van-field v-model="v_email_code" class="email-box-code" :placeholder="$t('请输入您的邮箱验证码')">
                            <template #button>
                                <div class="btn" v-if="timeout > 0" style="background-color: #cccccc;padding: 5px;color: #ffffff;min-width: 100px;text-align: center">{{timeout + 's'}}</div>
                                <div class="btn" @click="send" v-else style="background-color: #f95959;padding: 5px;color: #ffffff;min-width: 100px;text-align: center">{{$t('获取验证码')}}</div>
                            </template>
                        </van-field>
                        <van-field v-model="pay_password" v-if="y_email == ''"
                                   :placeholder="$t('请输入交易密码')"
                                   maxlength="6"
                                   type="password">
                        </van-field>
                        <van-field v-model="new_email" v-else
                                   :placeholder="$t('请输入新邮箱')">
                        </van-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-foot-space">
            <van-button @click="setMail" block color="linear-gradient(to right, #3270e0, #3653ea)" v-if="y_email == ''">{{$t('绑定')}}</van-button>
            <van-button @click="setMail" block color="linear-gradient(to right, #3270e0, #3653ea)" v-else>{{$t('更新绑定')}}</van-button>
        </div>
    </div>
</template>

<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                y_email: '',
                new_email: '',
                active: 1,
                show: false,
                show4: false,
                code: '',
                qegcode: '',
                pay_password:'',
                scdisabled: false,
                email: '',
                v_email_code: '',
                v_code: '',
                timeout: 0,
                timeout_limit: 60,
                send_flag: false,
            }
        },
        methods: {
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("last_timer");
                        return;
                    }
                    this.timeout -= 1;
                    localStorage.setItem("last_timer", this.timeout);
                }, 1000);
            },
            send(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }

                let that = this;
                let _email =  this.email;
                if(this.y_email != ''){
                    _email = this.y_email;
                }
                this.send_flag = true;
                this.$axios.post('/send_mail',
                    this.$qs.stringify({email: _email})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        that.timerInit(this.timeout_limit);
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            getMailInfo(){
                this.$axios.get('/get_mail_info').then((res) => {
                    if (res.data.code == 0) {
                        this.y_email = res.data.data.email;
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            setMail(){
                this.$toast.loading();

                if(this.send_flag){
                    return ;
                }

                let _email =  this.email;
                if(this.y_email != ''){
                    _email = this.y_email;
                }

                if(_email == ''){
                    this.$toast.fail(this.$t('请输入您的邮箱'));
                    return ;
                }
                if(this.v_email_code == ''){
                    this.$toast.fail(this.$t('请输入您的邮箱验证码'));
                    return ;
                }
                this.send_flag = true;
                let data = helper.encrypt(JSON.stringify({e:_email, ve:this.v_email_code, p:this.pay_password, n: this.new_email}));
                this.$axios.post('/set_email',
                    this.$qs.stringify({data: data})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        this.url('/my');
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },

        },
        mounted() {
            localStorage.setItem('aqzx', '1');
        },
        created() {
            this.getMailInfo();
        }
    }
</script>
